<template>
  <!-- 图片类型 -->
  <section class="app-container app-flex-col-container protoCategoryPage">
    <EHeader :query="query" :sup_this="sup_this" v-p="['externaladmin:product:category:addCategory']" />
    <PaginationBar
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
    <div class="flex-col-content" v-p="['externaladmin:product:category:categoryManage']">
      <el-table
        v-loading="tableLoading"
        :data="data"
        style="width: 100%; border: none"
        height="calc(100% - 20px)"
        border
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="分类"> </el-table-column>
        <el-table-column align="center" label="操作" width="280">
          <template slot-scope="{ row, $index }">
            <ColorTextBtn type="warning" v-if="$index == 0 && page == 1"> 系统预设分类，不可编辑和删除 </ColorTextBtn>
            <template v-else>
              <AddChildCate
                :sup_this="sup_this"
                :data="row"
                v-p="['externaladmin:product:category:addChildrenCategory']"
              ></AddChildCate>
              <EditCate :sup_this="sup_this" :data="row" v-p="['externaladmin:product:category:editCategory']" />
              <DelCate :sup_this="sup_this" :data="row" v-p="['externaladmin:product:category:deleteCategory']" />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <PaginationBar
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
  </section>
</template>

<script>
import EHeader from './module/header'
import { initDataMixin } from '@/mixins'
import AddChildCate from './module/addChildCate'
import EditCate from './module/editCate'
import DelCate from './module/delCate'

export default {
  mixins: [initDataMixin],
  components: {
    EHeader,
    AddChildCate,
    EditCate,
    DelCate
  },
  data() {
    return {
      url: '/externaladmin/productService/productCategory/list',
      sup_this: this,
      method: 'post'
    }
  },
  methods: {
    initCallBack(data) {
      console.log('data', data)
    }
  }
}
</script>
<style lang="scss" lang="scss">
.protoCategoryPage {
  .el-table::before,
  .el-table--border::after {
    background: transparent !important;
  }
}
</style>
